<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      :overlay="false"
      max-width="630px"
      transition="dialog-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn color="primary" rounded small class="mx-1" v-on="on"
          >Send UsaEpay Invoice</v-btn
        >
      </template>
      <v-card>
        <v-card-title class="teal white--text" primary-title>
          <div>
            <h3 class="headline mb-0">UsaEpay Invoice</h3>
            <p>{{ "Procedure: " + nameProcedure }}</p>
          </div>
        </v-card-title>
        <v-card-text class="my-2">
          <v-container>
            <v-row>
              <v-col>
                <ma-date-picker
                  :label="'Due Date'"
                  v-model="invoiceBody.due_date"
                  editable
                />
              </v-col>
              <v-col>
                <v-text-field
                  label="Discount"
                  type="number"
                  v-model="invoiceBody.discount"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider class="mb-1"></v-divider>
            <div class="text-center">Add Item</div>
            <v-divider class="mb-1"></v-divider>
            <v-row>
              <v-col cols="12" sm="3"
                ><v-text-field
                  label="Name"
                  v-model="newItem.name"
                  outlined
                  dense
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="4"
                ><v-text-field
                  label="Description"
                  v-model="newItem.description"
                  outlined
                  dense
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="2"
                ><v-text-field
                  label="Price"
                  v-model="newItem.cost"
                  outlined
                  dense
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="2"
                ><v-text-field
                  label="Quantity"
                  v-model="newItem.qty"
                  outlined
                  dense
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="1">
                <v-btn
                  :disabled="!validAdd"
                  icon
                  color="success"
                  @click="addItem"
                  ><v-icon>mdi-plus</v-icon></v-btn
                ></v-col
              >
            </v-row>
            <v-divider class="my-1"></v-divider>
            <div class="text-center">Items</div>
            <v-divider class="my-1"></v-divider>
            <template v-if="invoiceBody.items.length != 0">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Name</th>
                      <th class="text-left">Description</th>
                      <th class="text-left">Price</th>
                      <th class="text-left">Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in invoiceBody.items" :key="i">
                      <td>{{ item.name }}</td>
                      <td>{{ item.description }}</td>
                      <td>{{ item.cost }}</td>
                      <td>{{ item.qty }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancelSend()" color="error">cancel</v-btn>
          <v-btn
            text
            @click="confirmSend()"
            :disabled="!validSend || loading"
            :loading="loading"
            color="primary"
            >send</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import moment from "moment";
import { getAPI } from "@/api/axios-base";
import { notifyError, notifySuccess } from "@/components/Notification";
export default {
  components: { MaDatePicker },
  name: "usae-pay-invoice",
  props: {
    procedure: Object,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      invoiceBody: {
        procedureId: 0,
        due_date: "",
        discount: 0,
        items: [],
      },
      newItem: {
        name: "",
        description: "",
        cost: 0,
        qty: 1,
      },
    };
  },

  computed: {
    nameProcedure() {
      if (this.procedure == null && this.procedure == undefined) {
        return "";
      }
      if (this.procedure.surgeryType) {
        return this.procedure.surgeryType.name;
      }
      if (this.procedure.treatmentType) {
        return this.procedure.treatmentType.name;
      }
      return "";
    },
    validSend() {
      if (
        this.invoiceBody.discount != null &&
        this.invoiceBody.due_date != null &&
        this.invoiceBody.due_date != "" &&
        this.invoiceBody.items.length != 0
      ) {
        return true;
      }
      return false;
    },

    validAdd() {
      if (
        this.newItem.name != "" &&
        this.newItem.name != null &&
        this.newItem.description != "" &&
        this.newItem.description != null &&
        this.newItem.cost != 0 &&
        this.newItem.cost != null &&
        this.newItem.qty != 0 &&
        this.newItem.qty != null
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    addItem() {
      const body = Object.assign({}, this.newItem);
      body.cost = Number(body.cost);
      body.qty = Number(body.qty);
      this.invoiceBody.items.push(body);
      this.newItem = {
        name: "",
        description: "",
        cost: 0,
        qty: 1,
      };
    },
    cancelSend() {
      this.dialog = false;
      this.loading = false;
      this.newItem = {
        name: "",
        description: "",
        cost: 0,
        qty: 1,
      };
      this.invoiceBody = {
        procedureId: 0,
        due_date: "",
        discount: 0,
        items: [],
      };
    },
    confirmSend() {
      this.loading = true;
      const body = Object.assign({}, this.invoiceBody);
      body.procedureId = this.procedure.id;
      body.discount = Number(body.discount);
      body.due_date = moment(body.due_date).toISOString();
      getAPI
        .put("/sprocedures/sendUsaEpayInvoice", body)
        .then(() => {
          notifySuccess("UsaEpay Invoice has been Sent");
          this.cancelSend();
          this.$emit("sended");
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);

          this.loading = false;
        });
    },
  },
};
</script>
<style lang=""></style>
